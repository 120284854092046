import React from "react";

function Footer() {
  return (
    <div className="py-10 bg-black text-white">
      <div className="container mx-auto flex flex-col place-items-center sm:flex-row justify-between font-roboto text-xs text-gray-300 tracking-wide uppercase">
        <div className="mb-2 sm:mb-0">
          COPYRIGHT © {new Date().getFullYear()} MP
        </div>
        <div>
          <a
            href="https://www.linkedin.com/in/martinpettinari/"
            target="_blank"
            rel="noreferrer"
          >
            linkedin
          </a>
          <span className="mx-3">|</span>
          <a
            href="https://www.instagram.com/tinchoman/"
            target="_blank"
            rel="noreferrer"
          >
            instagram
          </a>
          <span className="mx-3">|</span>
          <a
            href="https://www.behance.net/Tinchoman"
            target="_blank"
            rel="noreferrer"
          >
            behance
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
