import React from "react";
import { FormattedMessage } from "react-intl";
import { GatsbyImage } from "gatsby-plugin-image";
import Footer from "../Footer";
import StructuredText from "../StructuredText";

function About({ data }) {
  return (
    <>
      <div
        className="container mx-auto pt-12 md:pt-24 text-white"
        style={{ marginTop: 70 }}
      >
        <h2 className="text-4xl text-center mb-12 md:mb-20">
          <FormattedMessage id="aboutTitle" />
        </h2>
      </div>
      <div className="bg-white">
        <div className="container flex flex-col mx-auto py-14 px-9 lg:flex-row lg:px-0 md:py-28">
          <div className="flex-1 mb-5 lg:mr-10 lg:mb-0">
            <GatsbyImage image={data.photo.gatsbyImageData} alt={data.title} />
          </div>
          <div className="lg:w-1/3">
            <h3 className="text-2xl mb-3">{data.title}</h3>
            <div className="font-roboto tracking-wide text-gray-500">
              <StructuredText data={data.description} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default About;
