import React from "react";
import {
  StructuredText as StructuredTextDatoCMS,
  renderRule,
} from "react-datocms";
import { isList, isParagraph, isLink } from "datocms-structured-text-utils";

function StructuredText({ data }) {
  return (
    <StructuredTextDatoCMS
      data={data}
      customRules={[
        renderRule(isParagraph, ({ children, key }) => {
          return (
            <p key={key} className="my-5 font-roboto tracking-wide">
              {children}
            </p>
          );
        }),
        renderRule(isList, ({ children, key }) => {
          return (
            <ul
              key={key}
              className="list-disc"
              style={{ paddingInlineStart: 30 }}
            >
              {children}
            </ul>
          );
        }),
        renderRule(isLink, ({ node, children, key }) => {
          return (
            <a key={key} href={node.url} className="text-blue-500">
              {children}
            </a>
          );
        }),
      ]}
    />
  );
}

export default StructuredText;
